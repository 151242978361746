import React from 'react';

function Footer() {
  return (
    <section className='footer'>
      <p>Created by Edward Foulds</p>
      <p>&copy; 2022</p>
    </section>
  );
}

export default Footer;
